import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Layout from '@/layout/DefaultLayout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/basic',
    component: Layout,
    children: [
      {
        path: '',
        name: 'BasicRule',
        component: () => import('@/views/BasicView.vue')
      }
    ]
  },
  {
    path: '/syntax',
    component: Layout,
    children: [
      {
        path: '',
        name: 'SyntaxRule',
        component: () => import('@/views/SyntaxRule.vue')
      }
    ]
  },
  {
    path: '/api',
    component: Layout,
    children: [
      {
        path: 'loc',
        name: 'ApiLoc',
        component: () => import('@/views/Api/ApiLoc.vue')
      },
      {
        path: 'method',
        name: 'ApiMethod',
        component: () => import('@/views/Api/ApiMethod.vue')
      },
      {
        path: 'call',
        name: 'ApiCall',
        component: () => import('@/views/Api/ApiCall.vue')
      }
    ]
  },
  {
    path: '/img',
    component: Layout,
    children: [
      {
        path: 'baisc',
        name: 'ImgBasic',
        component: () => import('@/views/Img/ImgBasic.vue')
      },
      {
        path: 'svg',
        name: 'ImgSvg',
        component: () => import('@/views/Img/ImgSvg.vue')
      }
    ]
  },
  {
    path: '/plugin',
    component: Layout,
    children: [
      {
        path: '',
        name: 'PluginBasic',
        component: () => import('@/views/PluginBasic.vue')
      }
    ]
  },
  {
    path: '/css',
    component: Layout,
    children: [
      {
        path: '',
        name: 'CssBasic',
        component: () => import('@/views/CssBasic.vue')
      }
    ]
  },
  {
    path: '/tools',
    component: Layout,
    children: [
      {
        path: '',
        name: 'UsefulTools',
        component: () => import('@/views/ToolsView.vue')
      }
    ]
  },
  {
    path: '/changelog',
    component: Layout,
    children: [
      {
        path: '',
        name: 'ChangeLog',
        component: () => import('@/views/ChangeLog.vue')
      }
    ]
  },
  {
    path: '/upload-file',
    component: Layout,
    redirect: '/file',
    children: [
      {
        path: '/file',
        name: 'uploadFile',
        component: () => import('@/views/fileUpload.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
