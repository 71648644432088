<template>
  <div class="layout">
    <router-view />
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.layout {
  margin: 24px 36px;
}
</style>
