<template>
  <div id="app">
    <el-menu :router="true" :default-active="$route.path">
      <div v-for="(menu, idx) in MENU" :key="`menu${idx}`" :index="menu.path">
        <el-submenu v-if="menu.children" :index="`1-${idx}`">
          <template slot="title">{{ menu.name }}</template>
          <el-menu-item
            v-for="(subMenu, subIdx) in menu.children"
            :key="`sub${subIdx}`"
            :index="subMenu.path"
            >{{ subMenu.name }}</el-menu-item
          >
        </el-submenu>
        <el-menu-item v-else :index="menu.path">{{ menu.name }}</el-menu-item>
      </div>
    </el-menu>
    <router-view />
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

const MENU = [
  {
    name: '首页',
    routeName: 'home',
    path: '/home'
  },
  {
    name: '基本规则',
    routeName: 'BasicRule',
    path: '/basic'
  },
  {
    name: '语法规则',
    routeName: 'SyntaxRule',
    path: '/syntax'
  },
  {
    name: '接口相关',
    children: [
      {
        name: '基本规则',
        routeName: 'ApiLoc',
        path: '/api/loc'
      },
      {
        name: '方法定义',
        routeName: 'ApiMethod',
        path: '/api/method'
      },
      {
        name: '方法调用',
        routeName: 'ApiCall',
        path: '/api/call'
      }
    ]
  },
  {
    name: '图片相关',
    children: [
      {
        name: '基本规则',
        routeName: 'ImgBasic',
        path: '/img/baisc'
      },
      {
        name: 'Svg',
        routeName: 'ImgSvg',
        path: '/img/svg'
      }
    ]
  },
  {
    name: 'CSS相关',
    routeName: 'CSSBasic',
    path: '/css'
  },
  {
    name: '插件相关',
    routeName: 'PluginBasic',
    path: '/plugin'
  },
  {
    name: '实用在线工具',
    routeName: 'UsefulTools',
    path: '/tools'
  },
  {
    name: 'Changelog',
    routeName: 'ChangeLog',
    path: '/changelog'
  },
  {
    name: '文件上传',
    routeName: 'uploadFile',
    path: '/upload-file'
  }
]

export default {
  name: 'HomeView',
  data() {
    return {
      MENU
    }
  },
  methods: {
    isEmpty
  }
}
</script>
<style lang="less">
#app {
  display: grid;
  grid-template-columns: 250px 1fr;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
