<template>
  <div class="content">
    <h1>前端开发规范手册1.0.0</h1>
    <ol>
      <p>制定此文档主要的目的在于:</p>
      <li>提高代码质量</li>
      <li>小组内部形成相对统一的代码风格</li>
      <li>节约组员跨项目时阅读理解代码的成本</li>
      <li>提升项目开发、维护、升级的效率</li>
      <li>加强代码容错</li>
      <li>
        规范性质的文档难免会夹杂一些带有个人风格的内容, 且很部分内容可能会与自己原有的习惯冲突,
        希望大家能理解、配合。
      </li>
      <li>有任何好的优化、补充建议，请没有顾虑的主动提出, 经讨论通过后可对文档进行更改。</li>
      <li>文档一经确认, 请开发人员务必严格遵守, code review中不符合文档规范的内容需要重写。</li>
    </ol>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.content {
  h1 {
    text-align: center;
    color: #000;
  }
}
</style>
